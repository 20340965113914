@import "node_modules/bootstrap/scss/bootstrap";

body {
    padding-top:56px;
    background: #f8f8f8;
}

h1, h2, h3, h4, h5, h6, p, ul li, ol li {
	color: #454555;
}

.hero {
	margin: 0;
	padding: 0;
	background: #fff;
	border: none;
	
	p {
		background: none;
		border: none;	
		margin: 0;
		padding: 0;
	}
}
.display-3 {
	font-weight: 700;
}

ul {
	li {
		
	}
}

.container {
	img {
		max-width: 100%;
		margin-bottom: 1em;
	}

	h3 {
		margin-top: 1em;
		margin-bottom: 1em; 
		padding-top: 1em;
		border-top: 3px dashed #eee;
		
		&.first {
			margin-top: 0;
			margin-bottom: 1em;	
			padding-top: 0;
			border-top: none;
		}
	}
}

.projects:before {
    height: 54px;
    content: "";
    display:block;
}

.footer {
    margin-top:3rem;
}
.navbar {
    border-bottom:1px solid #fff;
}

.archive {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	justify-content: center;
}

.project-details {
	width: 100%;
	margin: 0;
	background: #eff0f0 url(../img/bg.jpg) no-repeat center bottom;
	background-size: cover;
	text-align: center;
	padding: 2em 5%;
	border-top: 1px solid #ddd;
	box-shadow: inset 0px -20px 50px 0px rgba(0,0,0,0.2);
	
	h1 {
		text-align: center;
	}
	
	&.main a {
		margin: 0;
		text-decoration: none;
		font-weight: bold;
		
		&.scroll {
			padding: 0;
		}
	}
	
	.scroll-down {
		margin: o;
		padding: 0;	
		font-size: 3em;
		line-height: 1em;
	}
}

.project-headers {
	border-bottom: 2px solid #eee;
	padding-bottom: 1em;
	margin-top: 2em;
	margin-bottom: 1em;
	
	&:first-child {
		margin-top: 0;
	}
}

.project-image {
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.5);
}

ul.details {
	font-size: 0.9em;
}

.article {
	flex: 0 0 100%;
	justify-content: space-between;
    margin: 0 0 2em 0;
    box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
    background: #fff;
    overflow: hidden;
    border-radius: 0 0 3px 3px;
	
	h2 {
		border-bottom: 2px solid #eee;
		padding: 0.25em 0;
		margin-bottom: 0.5em;
	}
	
	img {
		display: block;
		width: 100%;
		max-width: 100%;
		height: auto;
		margin: 0 auto;
	}
	
	div {
		padding: 0.5em 1em;
	}
	
	p {
		font-weight: 400;
	}
	
	.img-link {
		display: block;
		margin-bottom: 0.5em;
	}
	
	&.hidden {
		display: none;
	}
}

@media (min-width: 475px) and (max-width: 1023px) {
	.article {
		flex: 0 0 48%;
	    margin: 1%;
	}
	.project-details {
		margin: 0 auto;
		text-align: center;
		padding: 2em 20%;
		
		&.main {
			text-align: center;	
		}		
		
		h1 {
			text-align: center;
		}
	}
}
	
@media (min-width: 1023px) {
	.article {
		flex: 0 0 18%;
	    margin: 1%;
	    
	    &.three-column {
			flex: 0 0 31.33%;
			margin: 1%;
		}
		
	    &.nine-column {
			flex: 0 0 62.66%;
			margin: 1%;
			
			ul {
				display: flex;
				flex-flow: row wrap;
				width: 100%;
				justify-content: center;
				
				li {
					flex: 0 0 50%;
				}
			}
		}
	}
	.project-details {
		margin: 0 auto;
		text-align: center;
		padding: 2em 20%;
		
		&.main {
			text-align: center;	
		}		
		
		h1 {
			text-align: center;
		}
	}
}